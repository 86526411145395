var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"mobile-only",staticStyle:{"margin":"10px"}},[_c('v-btn',{staticClass:"mt-10 d-sm-none text-none",style:({
        'font-family': 'Georama',
        'font-size': '16px',
        'font-weight': '400',
        'line-height': '20px',
        'letter-spacing': '0em',
        padding: '5px 10px',
      }),attrs:{"disabled":_vm.uploading,"outlined":""},on:{"click":_vm.redirectToAuthentication}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Retour ")],1)],1),(_vm.isVisible)?_c('div',{staticClass:"mobile-container",attrs:{"align":"center"}},[_c('h1',{staticClass:"text-center mb-2",style:({
        styleTitle: _vm.styleTitle,
        fontFamily: 'Georama',
        fontSize: 24,
        fontWeight: 700,
        marginTop: '5px',
      })},[_vm._v(" URL non reconnu ")]),_c('br'),_c('div',[_c('v-row',{staticClass:"pt-1"},[_c('v-col',[_c('div',{staticClass:"subtitle-1 text-center",staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000","margin-top":"10px"},attrs:{"align":"start"}},[_vm._v(" Veuillez scanner le QR code du compte de l'agence ou cliquer sur le lien fourni. ")]),_c('br')])],1)],1),_c('br'),_c('v-btn',{staticClass:"mt-4",style:({
        color: _vm.buttonTextValidColor,
        borderRadius: '30px',
        'text-transform': 'none',
        padding: '25px 40px',
        'font-family': 'Georama',
        'font-size': '16px',
        'font-weight': '600',
        'line-height': '20px',
        'letter-spacing': '0em',
        'text-align': 'center',
      }),attrs:{"color":_vm.color4},on:{"click":_vm.redirectToAuthentication}},[_vm._v("Retour à la connexion")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }