<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div>
      <div :rail="rail" permanent class="accueil-drawer">
        <Accueil></Accueil>
      </div>

      <div
        class="d-flex flex-column justify-center align-center margin-top: 20px;"
      >
        <v-img
          :src="icon"
          style="margin-top: 112px"
          class="mx-auto mt-12"
        ></v-img>
        <v-div
          class="mb-5 d-flex justify-center align-center"
          style="margin-top: 100px"
        >
          <v-card
            width="180"
            height="140"
            class="rounded-xl mt-10 mx-10"
            :color="color1"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showWalletCharge"
            >
            </v-btn>
            <div class="pt-5 px-5">
              <v-img
                width="40"
                height="33"
                src="../../assets/images/Vector.png"
                alt=""
                class="mx-3"
              />
            </div>
            <v-card-text
              :style="styleTitle + '; color:' + buttonTextValidColor"
            >
              <p
                style="font-weight: 600; font-size: 16px; font-family: Georama"
                class="pl-3"
              >
                Porte-monnaie
              </p>
            </v-card-text>
          </v-card>
          <v-card
            width="180"
            height="140"
            class="rounded-xl mt-10 mx-10"
            :color="color1"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showWaittingShootList"
            >
            </v-btn>
            <div class="pt-5 px-5">
              <v-img
                width="33"
                height="33"
                src="../../assets/images/Group_47.png"
                alt=""
                class="mx-3"
              />
            </div>
            <v-card-text
              :style="styleTitle + '; color:' + buttonTextValidColor"
            >
              <p
                style="font-weight: 600; font-size: 16px; font-family: Georama"
                class="pl-3"
              >
                Prise de photo en attente
              </p>
            </v-card-text>
          </v-card>
          <v-card
            width="180"
            height="140"
            class="rounded-xl mt-10 mx-10"
            :color="color1"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="showOrderList"
            >
            </v-btn>
            <div class="pt-5 px-5">
              <v-img
                width="34.65"
                height="37.9"
                src="../../assets/images/Group_50.png"
                alt=""
                class="mx-3"
              />
            </div>
            <v-card-text
              :style="styleTitle + '; color:' + buttonTextValidColor"
            >
              <p
                style="font-weight: 600; font-size: 16px; font-family: Georama"
                class="pl-3"
              >
                Suivi de commande
              </p>
            </v-card-text>
          </v-card>
          <v-card
            width="180"
            height="140"
            class="rounded-xl mt-10 mx-10"
            :color="color1"
          >
            <v-btn
              class="my-5 d-flex btn"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
              "
              @click="newShoot"
            >
            </v-btn>
            <div class="pt-5 px-5">
              <v-img
                width="40"
                height="33"
                src="../../assets/images/Group_49.png"
                alt=""
                class="mx-3"
              />
            </div>
            <v-card-text
              :style="styleTitle + '; color:' + buttonTextValidColor"
            >
              <p
                style="font-weight: 600; font-size: 16px; font-family: Georama"
                class="pl-3"
              >
                Prendre une photo
              </p>
            </v-card-text>
          </v-card>
        </v-div>
        <v-card
          style="width: 384px; height: 73px; margin-top: 100px"
          class="text-center rounded-xl"
          :color="buttonNeutralColor"
        >
          <v-card-text :style="styleTitle" class="d-flex align-center">
            <span
              :style="{
                fontSize: '34px',
                fontWeight: '700',
                fontFamily: 'Georama',
                color: color1,
              }"
              >{{ printCredit() }}</span
            >
            <p
              class="ml-2"
              style="
                font-weight: 600;
                font-size: 18px;
                font-family: Georama;
                line-height: 20px;
                margin-top: 20px;
              "
            >
              code(s) ePhoto restant(s)
            </p>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
export default {
  components: {
    Accueil,
  },
  data() {
    return {
      isVisible: false,
      agenceUuid: null,
      agenceId: null,
      credit: 0,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  methods: {
    showWalletCharge() {
      this.$router.push({
        name: 'Wallet-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showWaittingShootList() {
      this.$router.push({
        name: 'Waiting-orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    showOrderList() {
      this.$router.push({
        name: 'Orders-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    newShoot() {
      this.$router.push({
        name: 'New-order-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
          agence_id: localStorageService.getValue('agenceId'),
          agence_uid: localStorageService.getValue('agenceUuid'),
        },
      })
    },
    logout() {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
    printCredit() {
      if (this.credit > 1) {
        return this.credit
      } else {
        return this.credit
      }
    },
  },
  mounted() {
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService.get('/partner/info/' + uuid).then(response => {
        if (response.data) {
          this.credit = response.data.credit
          this.agenceUuid = response.data.partner_uuid
          this.agenceId = response.data.partner_id

          localStorageService.setObject(
            'partner_uid',
            this.$route.params.partner_uid,
          )
          localStorageService.setObject(
            'agenceUuid',
            response.data.partner_uuid,
          )
          localStorageService.setObject('agenceId', response.data.partner_id)
        }
      })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
.btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.custom-img {
  margin-top: 15px;
}
.background-container {
  display: flex;
  flex-direction: column;
}
</style>