<template>
  <div v-if="isVisible" align="center">
    <div><v-icon x-large color="red">mdi-alert-octagon</v-icon></div>
    <h1>Connexion perdue</h1>
    <br />
    <div>
      <v-row class="pt-1">
        <v-col>
          <div><v-icon>mdi-qrcode-scan</v-icon></div>
          <div class="text" align="start">
            Veuillez scanner le QR code d'authentification ou cliquer sur le
            lien fourni par votre agence.
          </div>
          <br />
          <v-divider></v-divider>
          <br />
          <div><v-icon>mdi-sync</v-icon></div>
          <br />
          <v-divider></v-divider>
          <br />
          <div><v-icon>mdi-tablet-cellphone</v-icon></div>
          <div class="text" align="start">
            Ce service n'est utilisable que sur iPhone, smartphone ou tablette.
          </div>
        </v-col>
      </v-row>
    </div>
    <br />
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'NotAuthorized',
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    const shortLiveApiKey = localStorageService.getValue('shortliveApiKey')
    if (shortLiveApiKey !== undefined) {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication',
        params: { id: shortLiveApiKey },
      })
      return
    }
    this.isVisible = true
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
