<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!--<div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        :to="{ name: '' }"
        :disabled="uploading"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div> --->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <v-container
      class="container-small d-flex flex-column justify-center align-center"
      style="margin-top: 100px"
    >
      <div>
        <h1
          class="text-center mb-2"
          :style="{
            styleTitle,
            fontFamily: 'Georama',
            fontSize: 24,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Porte-monnaie numérique
        </h1>
      </div>
      <div style="margin-top: 20px; d-flex flex-column">
        <div
          class="subtitle-1 text-center"
          style="
            font-family: 'Georama';
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #00000;
          "
        >
          <p>
            Ajoutez de l'argent à votre porte-monnaie électronique en spécifiant
            le montant désiré, puis confirmez l'opération
          </p>
          <p>
            Le prix d'achat d'un code Ephoto est de {{ ephotoPrice }} €, et le
            montant minimum de recharge est de
            {{ Math.ceil(ephotoPrice * 10) }} €.
          </p>
        </div>
      </div>
      <v-form @submit.prevent="validerMontant">
        <v-text-field
          v-model="montant"
          prepend-inner-icon="mdi-wallet-outline"
          placeholder="Montant en euro"
          type="number"
          required
          solo
          :min="5"
          style="margin-top: 30px; border-radius: 10px; width: 500px"
        ></v-text-field>
        <v-row>
          <v-col>
            <div>
              <v-btn
                :color="color5"
                v-if="goBack"
                @click="goBackAction()"
                block
                :style="{
                  color: color1,
                  borderRadius: '30px',
                  'text-transform': 'none',
                  padding: '25px 40px',
                  'font-family': 'Georama',
                  'font-size': '16px',
                  'font-weight': '600',
                  'line-height': '20px',
                  'letter-spacing': '0em',
                  'text-align': 'center',
                }"
              >
                Annuler
              </v-btn>
            </div>
          </v-col>
          <v-col>
            <div>
              <v-btn
                :color="color1"
                @click="validerMontant"
                :loading="loading"
                block
                :style="{
                  color: buttonTextValidColor,
                  borderRadius: '30px',
                  'text-transform': 'none',
                  padding: '25px 40px',
                  'font-family': 'Georama',
                  'font-size': '16px',
                  'font-weight': '600',
                  'line-height': '20px',
                  'letter-spacing': '0em',
                  'text-align': 'center',
                }"
              >
                Valider
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-form>
      <v-alert
        v-if="montantValide !== null"
        :type="montantValide ? 'success' : 'error'"
        outlined
        class="mt-4"
      >
        <p v-if="montantValide">Montant valide : {{ montant }} €</p>
        <p v-else>
          Montant invalide. Veuillez entrer un montant valide (minimum
          {{ Math.ceil(this.ephotoPrice * 10) }} €).
        </p>
      </v-alert>
      <v-card
        class="pa-6"
        style="border-radius: 20px; margin-top: 20px; width: 80%"
      >
        <v-data-table
          :headers="headers"
          :items="
            tableData.map(item => ({
              ...item,
              createdAt: formatCreatedAt(item.createdAt),
              montantAvecDevise: `${item.amount} ${item.currency}`,
            }))
          "
          :items-per-page="5"
        ></v-data-table>
      </v-card>
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500px">
            <v-card width="auto">
              <div style="text-align: right">
                <v-icon right size="large" @click="dialog = false"
                  >mdi-close-circle</v-icon
                >
              </div>
              <v-col>
                <v-card class="" elevation="0">
                  <stripe-element-payment
                    ref="paymentRef"
                    :pk="pk"
                    :elements-options="elementsOptions"
                    :confirm-params="confirmParams"
                    :locale="language"
                  />
                  <v-divider
                    inset
                    color="#212121"
                    style="margin: 20px"
                  ></v-divider>
                  <v-container
                    class="bg-surface-variant"
                    style="margin-top: 20px"
                  >
                    <v-row>
                      <v-col class="">
                        <v-sheet
                          class="d-flex flex-row align-center justify-end"
                        >
                          <v-btn
                            @click="pay"
                            rounded
                            class="ml-10"
                            style="
                              background-color: #000000;
                              color: white;
                              font-family: Georama;
                              text-transform: none;
                            "
                            variant="text"
                          >
                            Confirmer
                          </v-btn>
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </v-container>
  </div>
</template>

<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'
import { StripeElementPayment } from '@vue-stripe/vue-stripe'
import Accueil from './Accueil.vue'
export default {
  name: 'Wallet',
  components: {
    StripeElementPayment,
    Accueil,
  },
  data() {
    return {
      montant: null,
      realAmount: null,
      loading: false,
      montantValide: null,
      dialog: false,
      language: 'fr',
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      color1: localStorageService.getStyle(location.hostname, 'color1'),
      color4: localStorageService.getStyle(location.hostname, 'color4'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),

      headers: [
        { text: 'Date de création', value: 'createdAt' },
        { text: 'Numéro de facture', value: 'id' },
        { text: 'Montant', value: 'montantAvecDevise' },
      ],
      walletPrice: null,
      goBack: true,
      pk: process.env.VUE_APP_ENV_STRIPE_PUB_KEY,
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {},
      tableData: [],
    }
  },
  methods: {
    validerMontant() {
      if (
        this.ephotoPrice > 0 &&
        this.montant !== null &&
        !isNaN(this.montant) &&
        this.montant >= Math.ceil(this.ephotoPrice * 10)
      ) {
        requestService
          .post('/order/', {
            orderItems: [
              {
                quantity: 1,
                amount: this.montant,
                currency: 'EUR',
                priceId: this.walletPrice,
              },
            ],
          })
          .then(data => {
            if (data.data.amount == 0) {
              this.chargerDonnees()
            } else {
              localStorageService.setObject('orderId', data.data.id)
              localStorageService.setObject(
                'checkoutSessionId',
                data.data.checkoutSessionId,
              )
              this.elementsOptions.clientSecret = data.data.stripe_client_secret
              this.confirmParams.return_url =
                process.env.VUE_APP_WEB_APP +
                '/wallet-agence/' +
                this.$route.params.partner_uid
              this.dialog = true
            }
          })
          .catch(() => {
            this.$store.dispatch(
              'alert/error',
              "Erreur lors pendant l'achat de crédit.",
            )
          })
      } else {
        this.montantValide = false
      }
    },
    // Method to initiate payment
    pay() {
      this.$refs.paymentRef.submit() // Submit the payment form for payment processing
    },
    chargerDonnees() {
      this.dialog = false
      requestService
        .get('/wallet/wallet-product-type/' + this.$route.params.partner_uid)
        .then(response => {
          this.tableData = response.data.orders
          this.walletPrice = response.data.walletPrice
          this.ephotoPrice = response.data.unitPrice
        })
        .catch(error => {
          console.error('Erreur lors du chargement des données :', error)
        })
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
  },
  mounted() {
    this.chargerDonnees()
  },
}
</script>

<style scoped>
.accueil-drawer {
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
</style>
