<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!--<div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        v-if="goBack"
        @click="goBackAction()"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div>-->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    <div class="photo-capture-container">
      <h2
        class="photo-status"
        :style="{
          styleTitle,
          'font-family': 'Georama',
          'font-size': '24px',
          'font-weight': '700',
          marginTop: '50px',
        }"
      >
        Suivi de commande
      </h2>
      <v-row justify="center" align="center">
        <v-col cols="12" sm="10" md="8" lg="7">
          <v-card class="pa-6" style="border-radius: 20px; margin-top: -20px">
            <v-data-table
              :headers="headers"
              :items="
                items.map(item => ({
                  ...item,
                  createdAt: formatCreatedAt(item.createdAt),
                }))
              "
              :items-per-page="5"
            >
              <template v-slot:item.status="{ item }">
                <v-icon :color="getStatusColor(item.status)">
                  {{ getStatusIcon(item.status) }}
                </v-icon>
              </template>
              <template v-slot:item.method="{ item }">
                <v-icon>
                  {{ getPaymentMethod(item) }}
                </v-icon>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from './Accueil.vue'
export default {
  name: 'Orders',
  components: {
    Accueil,
  },
  data: () => ({
    styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
    buttonValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonValidColor',
    ),
    buttonTextValidColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextValidColor',
    ),
    buttonNeutralColor: localStorageService.getStyle(
      location.hostname,
      'buttonNeutralColor',
    ),
    buttonTextNeutralColor: localStorageService.getStyle(
      location.hostname,
      'buttonTextNeutralColor',
    ),
    color1: localStorageService.getStyle(location.hostname, 'color1'),
    color4: localStorageService.getStyle(location.hostname, 'color4'),
    icon: localStorageService.getStyle(location.hostname, 'icon'),
    iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    items: [],
    goBack: true,
    headers: [
      { text: '', value: 'status' },
      { text: '', value: 'method' },
      { text: '', value: 'agency_payment' },
      //{ text: '', value: 'agency_payment' },
      { text: 'Commande', value: 'id' },
      { text: 'Prénom', value: 'firstName' },
      { text: 'Nom', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'code ePhoto', value: 'codeEphoto' },
      { text: 'Date de création', value: 'createdAt' },
    ],
  }),
  methods: {
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString('fr-FR')
    },
    goBackAction() {
      this.$router.back()
      document.activeElement.blur()
    },
    formatCreatedAt(createdAt) {
      const date = new Date(createdAt)
      // Utilisez les méthodes de Date pour formater la date comme vous le souhaitez
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }
      return date.toLocaleDateString('fr-FR', options)
    },
    getStatusIcon(status) {
      switch (status) {
        case 'in_progress':
          return 'mdi-circle'
        case 'fulfilled':
          return 'mdi-circle'
        case 'rejected_photo':
        case 'rejected_signture':
          return 'mdi-circle'
        case 'pending_payment':
          return 'mdi-circle'
        default:
          return 'mdi-circle-outline'
      }
    },
    getStatusColor(status) {
      switch (status) {
        case 'in_progress':
          return 'orange'
        case 'fulfilled':
          return 'green'
        case 'rejected_photo':
        case 'rejected_signture':
          return 'red'
        case 'pending_payment':
          return 'grey'
        default:
          return 'black'
      }
    },
    getPaymentMethod(item) {
      if (item.agency_payment === 'true' && item.method === 'wallet') {
        return 'mdi-wallet'
      } else if (item.agency_payment === 'false' && item.method === 'wallet') {
        return 'mdi-bank'
      } else if (item.method === 'stripe_card') {
        return 'mdi-credit-card'
      } else {
        return 'mdi-circle-outline'
      }
    },
  },
  mounted() {
    var uuid = this.$route.params.partner_uid
    if (uuid !== null) {
      requestService.get('/partner/orders/' + uuid).then(response => {
        if (response.data) {
          this.items = response.data
        }
      })
    } else {
      this.$router.push({
        name: 'NotAuthorizedPartner',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    }
  },
}
</script>

<style scoped>
.photo-capture-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5%;
}

.photo-icon {
  margin: auto;
  margin-top: 5%;
}

.photo-status {
  text-align: center;
  margin-bottom: 5%;
}

.photo-details {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.photo-name,
.photo-email,
.photo-timestamp {
  margin-bottom: 5px;
}
.accueil-drawer {
  width: 300px; /* Ajustez la largeur du drawer selon vos besoins */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
}
.background-container {
  display: flex;
  flex-direction: column;
}
</style>
