var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[_c('h2',{staticClass:"text-center mb-5",style:({
        styleTitle: _vm.styleTitle,
        fontFamily: 'Georama',
        fontSize: 24,
        fontWeight: 700,
        marginTop: '35px',
        lineheight: '20px',
      })},[_vm._v(" Votre commande est en cours de traitement ")]),_c('v-row',{staticClass:"mt-13",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-card',{staticClass:"rounded-xl pa-2 mx-10",staticStyle:{"margin-bottom":"30px"},attrs:{"width":"350","height":"204","color":_vm.color,"variant":_vm.variant}},[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_c('v-card-item',[_c('v-card-title',{style:({
                  fontWeight: 600,
                  fontSize: '16px',
                  fontFamily: _vm.Georama,
                  lineHeight: '20px',
                })},[_c('span',{staticClass:"pr-5",staticStyle:{"font-weight":"700","font-size":"34px","font-family":"Georama","line-height":"20px"}},[_vm._v("1")]),_vm._v("Traitement ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"600","font-size":"14px","font-family":"Georama","line-height":"20px"}},[_vm._v(" Votre commande sera analysée par nos équipes ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"400","font-size":"14px","font-family":"Georama","line-height":"20px","padding-top":"20px"}},[_vm._v(" Si votre photo ou signature risque d’être refusée par l’administration, vous serez invité, par email ou par votre agence à refaire votre photo ou signature "),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("gratuitement")]),_vm._v(". ")])],1)],1)],1),_c('v-card',{staticClass:"rounded-xl pa-2 mx-10",attrs:{"width":"350","height":"204","color":_vm.color,"variant":_vm.variant}},[_c('v-row',{staticClass:"pa-3",attrs:{"align":"center"}},[_c('v-card-item',[_c('v-card-title',{style:({
                  fontWeight: 600,
                  fontSize: '16px',
                  fontFamily: _vm.Georama,
                  lineHeight: '20px',
                })},[_c('span',{staticClass:"pr-5",staticStyle:{"font-weight":"700","font-size":"34px","font-family":"Georama"}},[_vm._v("2")]),_vm._v("Validation ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"600","font-size":"14px","font-family":"Georama","line-height":"20px"}},[_vm._v(" Une fois votre commande validée, un code ePhoto sera envoyé à votre agence ")]),_c('div',{staticClass:"pl-5",staticStyle:{"font-weight":"400","font-size":"14px","font-family":"Georama","line-height":"20px","padding-top":"20px"}},[_vm._v(" Merci pour votre confiance. ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }