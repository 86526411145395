<template>
  <div
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <div class="mobile-only" style="margin: 10px">
      <v-btn
        class="mt-10 d-sm-none text-none"
        @click="redirectToAuthentication"
        :disabled="uploading"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div>
    <div v-if="isVisible" align="center" class="mobile-container">
      <h1
        class="text-center mb-2"
        :style="{
          styleTitle,
          fontFamily: 'Georama',
          fontSize: 24,
          fontWeight: 700,
          marginTop: '5px',
        }"
      >
        URL non reconnu
      </h1>
      <br />
      <div>
        <v-row class="pt-1">
          <v-col>
            <div
              align="start"
              class="subtitle-1 text-center"
              style="
                font-family: 'Georama';
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #00000;
                margin-top: 10px;
              "
            >
              Veuillez scanner le QR code du compte de l'agence ou cliquer sur
              le lien fourni.
            </div>
            <br />
          </v-col>
        </v-row>
      </div>
      <br />
      <v-btn
        :color="color4"
        class="mt-4"
        @click="redirectToAuthentication"
        :style="{
          color: buttonTextValidColor,
          borderRadius: '30px',
          'text-transform': 'none',
          padding: '25px 40px',
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '600',
          'line-height': '20px',
          'letter-spacing': '0em',
          'text-align': 'center',
        }"
        >Retour à la connexion</v-btn
      >
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'NotAuthorizedAgence',
  data() {
    return {}
  },
  methods: {
    redirectToAuthentication() {
      this.$router.push({
        name: 'Authentication-agence',
        params: {
          partner_uid: this.$route.params.partner_uid,
        },
      })
    },
  },
  mounted() {
    localStorageService.clearAll()
  },
}
</script>

<style scoped>
.mobile-only {
  margin: 10px;
}
.mobile-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .mobile-container {
    width: 90%;
  }
}
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
