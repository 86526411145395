<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :sessionId="sessionId"
      @loading="v => (loading = v)"
    />
    <button @click="submit">
      Vous allez être redirigé vers la page de paiement...
      <v-progress-circular indeterminate color="red"></v-progress-circular>
    </button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'Payment',
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_ENV_STRIPE_PUB_KEY
    return {
      loading: false,
      sessionId: localStorageService.getValue('checkoutSessionId'),
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout()
    },
  },
  mounted() {
    if (localStorageService.getValue('shortliveApiKey') === null) {
      this.$router.push({ name: 'NotAuthorized' })
    } else if (localStorageService.getValue('signatureUid') === null) {
      this.$router.push({ name: 'Signature' })
    }
    this.submit()
  },
}
</script>
