var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/BG.svg') + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('div',{staticClass:"photo-capture-container"},[_c('div',{staticClass:"photo-status"},[_c('h2',{style:({
          styleTitle: _vm.styleTitle,
          'font-family': 'Georama',
          'font-size': '24px',
          'font-weight': '700',
          marginTop: '5px',
        })},[_vm._v(" Prise de photo en attente ")]),_c('h6',[_vm._v("Ces élèves n'ont pas encore pris leur photo.")])]),_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10","md":"8","lg":"7"}},[_c('v-card',{staticClass:"pa-6",staticStyle:{"border-radius":"20px","margin-top":"-45px"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.map(function (item) { return (Object.assign({}, item,
                {createdAt: _vm.formatCreatedAt(item.createdAt)})); }),"items-per-page":5}})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }