<template>
  <v-container
    fluid
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'stretch',
      overflowY: 'hidden',
      backgroundSize: '57% 100%',
      backgroundPosition: 'right center',
    }"
    class="background-container"
  >
    <v-row>
      <v-card
        class="d-flex align-center justify-center"
        :style="{
          borderRadius: '0px 25px 25px 0px',
          width: '45%',
          height: 'auto',
        }"
      >
        <v-sheet>
          <v-img :src="icon" :width="iconWidth" class="mx-auto mb-10"></v-img>
          <h2
            class="text-center"
            :style="{ backgroundColor: styleTitle }"
            v-if="!stillLogged"
          >
            Connexion
          </h2>
          <v-alert type="error" v-if="errors.length">
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </v-alert>
          <br />
          <v-sheet v-if="step === 1 && !stillLogged">
            <div style="display: flex; justify-content: center">
              <span
                style="
                  font-family: 'Georama';
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #00000;
                "
                >Veuillez renseigner l'email de votre compte
              </span>
            </div>
            <div
              style="display: flex; justify-content: center; margin-top: 5px"
            >
              <span
                style="
                  font-family: 'Georama';
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #00000;
                "
                >pour recevoir le code de connexion.
              </span>
            </div>
            <br />
            <v-sheet class="d-flex justify-center">
              <!--<v-icon left>mdi-email</v-icon> -->
              <!--<v-text-field label="Email" v-model="email" :outlined="false" :style="{ flex: '1', marginLeft: '5px' }"></v-text-field> -->
              <v-text-field
                density="dense"
                v-model="email"
                placeholder="Email"
                prepend-inner-icon="mdi-email-outline"
                style="width: 400px"
                solo
                :style="{ 'margin-top': '10px' }"
              ></v-text-field>
            </v-sheet>

            <v-sheet class="d-flex justify-center mt-5">
              <v-btn
                @click="submitEmail"
                :color="color1"
                :style="{
                  color: buttonTextValidColor,
                  borderRadius: '30px',
                  'text-transform': 'none',
                  padding: '25px 50px',
                  'font-family': 'Georama',
                  'font-size': '16px',
                  'font-weight': '600',
                  'line-height': '20px',
                  'letter-spacing': '0em',
                  'text-align': 'center',
                }"
                >Recevoir le code</v-btn
              >
            </v-sheet>
          </v-sheet>
          <v-sheet v-if="step === 2 && !stillLogged">
            <div style="display: flex; justify-content: center">
              <span
                style="
                  font-family: 'Georama';
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 20px;
                  text-align: center;
                  color: #00000;
                "
                >Veuillez saisir le code à 6 chiffres reçu par email.
              </span>
            </div>
            <br />
            <!-- <v-text-field label="Code" v-model="code"></v-text-field>-->
            <div class="d-flex justify-center">
              <v-text-field
                v-model="code"
                solo
                style="max-width: 200px"
              ></v-text-field>
            </div>
            <!--<v-progress-linear :value="(100 * timer) / 180" height="25" reverse>
        {{ formatTime(timer) }}
      </v-progress-linear> -->
            <v-sheet
              class="d-flex flex-column align-center justify-center mt-5"
            >
              <v-btn
                @click="submitCode"
                :color="color1"
                :style="{
                  color: buttonTextValidColor,
                  borderRadius: '30px',
                  'text-transform': 'none',
                  padding: '25px 50px',
                  'font-family': 'Georama',
                  'font-size': '16px',
                  'font-weight': '600',
                  'line-height': '20px',
                  'letter-spacing': '0em',
                  'text-align': 'center',
                }"
                >Se Connecter</v-btn
              >
              <br />
              <a
                href="#"
                @click="newCode"
                :style="{
                  color: color1,
                  textDecoration: 'underline',
                  marginTop: '10px',
                  fontWeight: 'bold',
                  'font-family': 'Georama',
                  'font-size': '16px',
                  'font-weight': '600',
                  'line-height': '20px',
                  'letter-spacing': '0em',
                  'text-align': 'center',
                }"
                >{{ 'Renvoyer un nouveau code' }}</a
              >
            </v-sheet>
          </v-sheet>
        </v-sheet>
      </v-card>

      <!-- <v-col class="d-none d-lg-flex" lg="10"></v-col>-->
    </v-row>
  </v-container>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
export default {
  name: 'Authentication',
  data() {
    return {
      step: 1,
      email: '',
      code: '',
      authenticated: false,
      userEmail: '',
      timer: 180,
      timerCounter: null,
      errors: [],
      stillLogged: true,
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),

      color1: localStorageService.getStyle(location.hostname, 'color1'),
      color2: localStorageService.getStyle(location.hostname, 'color2'),
      color3: localStorageService.getStyle(location.hostname, 'color3'),
    }
  },
  methods: {
    submitEmail() {
      // Simuler une vérification de l'email côté serveur
      // Vous devriez envoyer l'email au serveur pour validation réelle
      // et recevoir une réponse indiquant si l'email est valide.
      // Ici, nous simplifions en considérant l'email comme valide.

      //requets to receive code by email

      this.step = 2
      this.timer = 180
      this.timerCounter = setInterval(() => {
        if (this.timer > 0) {
          this.timer--
        } else {
          this.newCode()
        }
      }, 1000)

      requestService
        .post('/email-auth-request', { email: this.email })
        .then(() => {})
    },
    submitCode() {
      // Simuler une vérification du code côté serveur
      // Vous devriez envoyer le code au serveur pour validation réelle
      // et recevoir une réponse indiquant si le code est valide.
      // Ici, nous simplifions en considérant le code comme valide.
      //requets to verify the code
      requestService
        .post('/check-auth-code', { email: this.email, code: this.code })
        .then(response => {
          if (response.data.token) {
            clearInterval(this.timerCounter)
            localStorageService.setObject('token', response.data.token)
            localStorageService.setObject('partnerEmail', this.email)
            localStorageService.setObject(
              'refresh_token',
              response.data.refresh_token,
            )
            this.$router.push({
              name: 'Home-agence',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
          } else {
            this.errors = ['Code erroné']
          }
        })
    },
    newCode() {
      this.email = ''
      this.code = ''
      this.step = 1
      this.errors = []
      this.authenticated = false
      if (this.timerCounter !== null) {
        clearInterval(this.timerCounter)
      }
    },
    formatTime(timer) {
      return (
        (timer - (timer % 60)) / 60 +
        ' : ' +
        (timer % 60 < 10 ? '0' : '') +
        (timer % 60)
      )
    },
    formatCode() {
      // Supprime les espaces existants et ajoute des espaces tous les 4 caractères
      this.code = this.code.replace(/\s/g, '').replace(/(.{1})/g, '$1 ')
    },
  },

  mounted() {
    var uuid = this.$route.params.partner_uid
    this.stillLogged = true
    const refresh_token = localStorageService.getValue('refresh_token')

    if (refresh_token !== null && refresh_token !== undefined) {
      requestService
        .post('/token/refresh', { refresh_token: refresh_token })
        .then(response => {
          if (response.data.token) {
            localStorageService.setObject('token', response.data.token)
            localStorageService.setObject(
              'refresh_token',
              response.data.refresh_token,
            )
            this.$router.push({
              name: 'Home-agence',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
            uuid = null
            this.stillLogged = true
          } else {
            this.stillLogged = false
            uuid = this.$route.params.partner_uid
            localStorageService.clearAll()
          }
        })
    } else {
      this.stillLogged = false
      uuid = this.$route.params.partner_uid
      localStorageService.clearAll()
    }

    if (!this.stillLogged) {
      if (uuid !== undefined && uuid !== null) {
        requestService.get('/partner/uuid/' + uuid).then(response => {
          if (response.data.success) {
            this.company = response.data.company
            this.partnerPlan = response.data.partnerPlan
            this.partnerAdmin = response.data.partnerAdmin
            if (response.data.logo !== null) this.icon = response.data.logo
            localStorageService.setObject(
              'logo',
              response.data.logo !== null ? response.data.logo : undefined,
            )
            localStorageService.setObject(
              'primaryColor',
              response.data.primaryColor !== null
                ? response.data.primaryColor
                : undefined,
            )
            localStorageService.setObject(
              'secondaryColor',
              response.data.secondaryColor !== null
                ? response.data.secondaryColor
                : undefined,
            )
          } else {
            // partner non reconnu
            this.$router.push({
              name: 'NotAuthorizedPartner',
              params: {
                partner_uid: this.$route.params.partner_uid,
              },
            })
          }
        })
      }
    }
  },
}
</script>
<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}

a {
  color: black;
}
.background-container {
  /*background-size: cover;*/
  height: 100vh !important; /* Prend toute la hauteur de l'écran */
  width: 100vw !important; /* Prend toute la largeur de l'écran */
  display: flex;
}
.code-inputs {
  display: flex;
}
html,
body {
  height: 100% !important;
  margin: 0 !important;
  overflow: hidden !important; /* Désactive le défilement vertical et horizontal sur le corps et l'html */
}

.v-card {
  border-radius: 0px 25px 25px 0px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
