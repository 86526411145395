var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"background-container",style:({
    backgroundImage: 'url(' + require('@/assets/images/auto.png') + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'stretch',
    overflowY: 'hidden',
    backgroundSize: '57% 100%',
    backgroundPosition: 'right center',
  }),attrs:{"fluid":""}},[_c('v-row',[_c('v-card',{staticClass:"d-flex align-center justify-center",style:({
        borderRadius: '0px 25px 25px 0px',
        width: '45%',
        height: 'auto',
      })},[_c('v-sheet',[_c('v-img',{staticClass:"mx-auto mb-10",attrs:{"src":_vm.icon,"width":_vm.iconWidth}}),(!_vm.stillLogged)?_c('h2',{staticClass:"text-center",style:({ backgroundColor: _vm.styleTitle })},[_vm._v(" Connexion ")]):_vm._e(),(_vm.errors.length)?_c('v-alert',{attrs:{"type":"error"}},[_c('ul',_vm._l((_vm.errors),function(error){return _c('li',{key:error.id},[_vm._v(_vm._s(error))])}),0)]):_vm._e(),_c('br'),(_vm.step === 1 && !_vm.stillLogged)?_c('v-sheet',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000"}},[_vm._v("Veuillez renseigner l'email de votre compte ")])]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","margin-top":"5px"}},[_c('span',{staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000"}},[_vm._v("pour recevoir le code de connexion. ")])]),_c('br'),_c('v-sheet',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticStyle:{"width":"400px"},style:({ 'margin-top': '10px' }),attrs:{"density":"dense","placeholder":"Email","prepend-inner-icon":"mdi-email-outline","solo":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-sheet',{staticClass:"d-flex justify-center mt-5"},[_c('v-btn',{style:({
                color: _vm.buttonTextValidColor,
                borderRadius: '30px',
                'text-transform': 'none',
                padding: '25px 50px',
                'font-family': 'Georama',
                'font-size': '16px',
                'font-weight': '600',
                'line-height': '20px',
                'letter-spacing': '0em',
                'text-align': 'center',
              }),attrs:{"color":_vm.color1},on:{"click":_vm.submitEmail}},[_vm._v("Recevoir le code")])],1)],1):_vm._e(),(_vm.step === 2 && !_vm.stillLogged)?_c('v-sheet',[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('span',{staticStyle:{"font-family":"'Georama'","font-weight":"400","font-size":"16px","line-height":"20px","text-align":"center","color":"#00000"}},[_vm._v("Veuillez saisir le code à 6 chiffres reçu par email. ")])]),_c('br'),_c('div',{staticClass:"d-flex justify-center"},[_c('v-text-field',{staticStyle:{"max-width":"200px"},attrs:{"solo":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('v-sheet',{staticClass:"d-flex flex-column align-center justify-center mt-5"},[_c('v-btn',{style:({
                color: _vm.buttonTextValidColor,
                borderRadius: '30px',
                'text-transform': 'none',
                padding: '25px 50px',
                'font-family': 'Georama',
                'font-size': '16px',
                'font-weight': '600',
                'line-height': '20px',
                'letter-spacing': '0em',
                'text-align': 'center',
              }),attrs:{"color":_vm.color1},on:{"click":_vm.submitCode}},[_vm._v("Se Connecter")]),_c('br'),_c('a',{style:({
                color: _vm.color1,
                textDecoration: 'underline',
                marginTop: '10px',
                fontWeight: 'bold',
                'font-family': 'Georama',
                'font-size': '16px',
                'font-weight': '600',
                'line-height': '20px',
                'letter-spacing': '0em',
                'text-align': 'center',
              }),attrs:{"href":"#"},on:{"click":_vm.newCode}},[_vm._v(_vm._s('Renvoyer un nouveau code'))])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }